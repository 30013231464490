@keyframes word_fadein {
  0% {
    opacity: 0.5;
    transform: scaleX(0.9);
  }

  50% {
    opacity: 0.9;
    transform: scaleX(1.05);
  }

  100% {
    filter: blur(0px);
    opacity: 1;
    transform: scaleX(1);
  }
}

@keyframes word_fadeout {
  0% {
    opacity: 1;
    filter: blur(5px);
  }

  100% {
    opacity: 0;
  }
}

.locl-home-intro {
  height: 715px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--white-color-500);
  overflow: hidden;

  &__image-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    mix-blend-mode: normal;
    filter: brightness(40%);
  }

  &__welcome {
    margin-top: 175px;
    text-align: center;

    &__title {
      font-family: var(--secondary-font-family);
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 120%;
      color: var(--primary-color-500);
      margin-bottom: 34px;
    }

    &__subtitle {
      font-size: 28px;
      margin-bottom: 38px;
    }

    &__content {
      font-style: normal;
      font-weight: 400;
      font-size: 28px;
      line-height: 44px;
      margin: 0 auto 95px;
      max-width: 958px;
    }

    &__brands {
      width: 100%;

      &__scroll {
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;
        margin: 40px auto;
        cursor: pointer;

        &__icon {
          border: 1px solid var(--white-color-500);
          border-radius: 50px;
          height: 27px;
          width: 27px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 8px;

          &__img {
            position: static;
            width: 12px;
            height: 12px;
          }
        }

        &__text {
          font-family: var(--secondary-font-family);
          font-weight: 700;
          margin-bottom: 0;
          text-decoration-line: underline;
        }
      }
    }
  }

  .bounce {
    transform: scaleX(1.01);
    transition: 0.4s;
  }

  .animated_word {
    margin-left: 2px;
    opacity: 0;
    position: absolute;
    transform-origin: 20% 50%;
    left: 0;
  }

  .fadedIn {
    animation: 0.45s forwards ease-in-out word_fadein;
  }

  .fadedOut {
    opacity: 1;
    animation: 0.4s forwards ease word_fadeout;
  }

  .animation_text {
    font-family: var(--secondary-font-family);
    color: var(--white-color-500);
    font-size: 84px;
    line-height: 38px;
    font-weight: 700;
    margin: 0 0 32px 0;
    transition: 0.5s;
    right: 0;

    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    label {
      color: var(--danger-color-500);
    }

    span {
      color: var(--white-color-500);
    }
  }

  .animated_word-hidden {
    width: 145px;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 870px) {
  .locl-home-intro {
    height: 450px;

    &__welcome {
      margin-top: 90px;

      &__title {
        font-size: 24px;
        margin-bottom: 10px;
      }

      &__content {
        margin: 0 auto;
        width: 95%;
      }

      &__subtitle,
      &__content {
        line-height: 24px;
        font-size: 16px;
      }
    }

    .animation_text {
      font-size: 44px;
      line-height: 32px;
      margin: 0 auto 24px auto;
    }
  }
}
